import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Strong, Icon, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiPassport } from "react-icons/gi";
import { FaSearch, FaLock, FaUserShield, FaChartLine } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Black Border
			</title>
			<meta name={"description"} content={"Step into the role of an immigration officer, managing border control and making tough decisions in *Black Border Patrol Simulator*."} />
			<meta property={"og:title"} content={"Black Border"} />
			<meta property={"og:description"} content={"Step into the role of an immigration officer, managing border control and making tough decisions in *Black Border Patrol Simulator*."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.56) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/game-6.webp?v=2024-11-11T15:21:23.639Z) 50% 40% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="50%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					Patrol
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline1" color="white">
					Black Border
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Step into the role of an immigration officer, managing border control and making tough decisions in *Black Border Patrol Simulator*.
				</Text>
				<Button
					font="normal 400 16px/1.5 --fontFamily-sans"
					background="--color-dark"
					padding="12px 28px 12px 28px"
					type="link"
					href="https://play.google.com/store/apps/details?id=com.bitzooma.blackborder&hl=en"
					text-decoration-line="initial"
				>
					Download
				</Button>
			</Box>
			<Box
				min-width="250px"
				min-height="250px"
				width="250px"
				height="250px"
				background="--color-darkL1"
				border-radius="50%"
				display="flex"
				align-items="center"
				justify-content="center"
				flex-direction="column"
				md-align-self="flex-end"
			>
				<Image src="https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15:21:10.471Z" srcSet="https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15%3A21%3A10.471Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15%3A21%3A10.471Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15%3A21%3A10.471Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15%3A21%3A10.471Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15%3A21%3A10.471Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15%3A21%3A10.471Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/fav.png?v=2024-11-11T15%3A21%3A10.471Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="60px 0 60px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Game Scenario"
			id="about"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 48px 0px"
				lg-justify-content="center"
			>
				<Text as="p" font="--base" color="--red" margin="0px 0px 8px 0px">
					<Strong font="--lead">
						Experience Life on the Border
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-text-align="center"
				>
					What the Game is About
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					Black Border Patrol Simulator puts you in the challenging position of a border officer. Inspect documents, detect fake IDs, and prevent illegal entries in this intense simulation.
				</Text>
			</Box>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="24px 32px"
				lg-margin="32px 0px 0px 0px"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiPassport}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Document Verification.
						</Span>
						<Span opacity="0.9" font="--base">
							{" "}Carefully inspect passports and permits to detect any irregularities or fake IDs.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiPassport}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Immigration Control.
						</Span>
						<Span opacity="0.9" font="--base">
							{" "}Handle cases involving prohibited items, contraband, and unauthorized individuals.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiPassport}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--light">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Realistic Decision-Making.
						</Span>
						<Span opacity="0.9" font="--base">
							{" "}Make impactful choices that can alter lives and affect your career as an officer.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiPassport}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Security Enhancements.
						</Span>
						<Span opacity="0.9" font="--base">
							{" "}Implement border policies and upgrades to improve efficiency and security.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiPassport}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Stress Management.
						</Span>
						<Span opacity="0.9" font="--base">
							{" "}Handle the pressure of long lines, difficult cases, and the responsibility of maintaining order.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiPassport}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--light">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Career Progression.
						</Span>
						<Span opacity="0.9" font="--base">
							{" "}Your success or failure determines your rise through the ranks, facing tougher cases and higher stakes.
						</Span>
					</Text>
				</Box>
			</List>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			background="rgba(0, 0, 0, 0) linear-gradient(120deg, white 0%, --color-lightD2 100%) 0% 0% /auto repeat scroll padding-box"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Benefits/Features"
			id="benefits"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="55%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					md-text-align="center"
					margin="0px 0px 16px 0px"
					font="--base"
					color="--orange"
					letter-spacing="1px"
				>
					BENEFITS OF PLAYING
				</Text>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Why You'll Love Black Border
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaSearch}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Intense Decision-Making
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Your decisions impact every interaction, from verifying documents to assessing risk.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaLock}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Realistic Simulation
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Authentic experiences in border control, testing your patience and attention to detail.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaUserShield}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Role-Based Progression
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Move up in rank as you navigate challenging situations, learning the ins and outs of border patrol.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaChartLine}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Skill Development
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Sharpen observation, patience, and problem-solving skills in a highly engaging way.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="#111828" padding="90px 0 110px 0" quarkly-title="Showcase-10">
			<Text margin="0px 0px 0px 0px" color="--light" text-align="center" font="600 48px --fontFamily-sans">
				Meet the Key Characters
			</Text>
			<Text text-align="center" color="--lightD2" font="300 22px --fontFamily-sansHelvetica">
				Discover the personalities that define the Black Border Patrol Simulator world, each with unique roles and stories.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				margin="70px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="repeat(1, 1fr)"
				grid-gap="30px 30px"
				padding="0px 0 0px 0"
				lg-padding="0px 0 0px 0"
				lg-margin="50px 0px 0px 0px"
				lg-grid-gap="30px 20px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="repeat(3, 1fr)"
				md-grid-gap="36px 25px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="row"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					padding="50px 50px 50px 50px"
					background="#202938"
					border-radius="25px"
					sm-padding="28px 28px 28px 28px"
					lg-padding="35px 35px 35px 35px"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15:21:23.657Z"
						display="block"
						width="220px"
						border-radius="50%"
						lg-width="180px"
						sm-width="100%"
						lg-max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15%3A21%3A23.657Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15%3A21%3A23.657Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15%3A21%3A23.657Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15%3A21%3A23.657Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15%3A21%3A23.657Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15%3A21%3A23.657Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-4.webp?v=2024-11-11T15%3A21%3A23.657Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="25px 0px 0px 0px" color="--light" font="600 18px --fontFamily-sans" text-align="center">
						Officer Carter
					</Text>
					<Text margin="8px 0px 0px 0px" color="--grey" font="300 17px --fontFamily-sansHelvetica" text-align="center">
						Vigilant Border Patrol Officer
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					padding="50px 50px 50px 50px"
					background="#202938"
					border-radius="25px"
					sm-padding="28px 28px 28px 28px"
					lg-padding="35px 35px 35px 35px"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15:21:23.636Z"
						display="block"
						width="220px"
						border-radius="50%"
						lg-width="180px"
						sm-width="100%"
						lg-max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15%3A21%3A23.636Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15%3A21%3A23.636Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15%3A21%3A23.636Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15%3A21%3A23.636Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15%3A21%3A23.636Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15%3A21%3A23.636Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-2.webp?v=2024-11-11T15%3A21%3A23.636Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="25px 0px 0px 0px" color="--light" font="600 18px --fontFamily-sans" text-align="center">
						Inspector Kelly
					</Text>
					<Text margin="8px 0px 0px 0px" color="--grey" font="300 17px --fontFamily-sansHelvetica" text-align="center">
						Chief Inspector of Security
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					color="--grey"
					padding="50px 50px 50px 50px"
					background="#202938"
					border-radius="25px"
					sm-padding="28px 28px 28px 28px"
					lg-padding="35px 35px 35px 35px"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15:21:23.641Z"
						display="block"
						width="220px"
						border-radius="50%"
						lg-width="180px"
						sm-width="100%"
						lg-max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15%3A21%3A23.641Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15%3A21%3A23.641Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15%3A21%3A23.641Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15%3A21%3A23.641Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15%3A21%3A23.641Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15%3A21%3A23.641Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e80be778710018d4c1d7/images/game-7.webp?v=2024-11-11T15%3A21%3A23.641Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="25px 0px 0px 0px" color="--light" font="600 18px --fontFamily-sans" text-align="center">
						Agent Ramirez
					</Text>
					<Text margin="8px 0px 0px 0px" color="--grey" font="300 17px --fontFamily-sansHelvetica" text-align="center">
						Intelligence Analyst
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10" id="faq">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Frequently Asked Questions
					</Text>
					<Text
						margin="0px 0px 1rem 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Here are the most frequently asked questions about *Black Border Patrol Simulator* and the{" "}
						<Span color="--secondary" font="500 20px/30px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif">
							answers
						</Span>
						{" "}to them.
					</Text>
					<Button
						font="normal 400 16px/1.5 --fontFamily-sans"
						background="--color-dark"
						padding="12px 28px 12px 28px"
						type="link"
						href="https://play.google.com/store/apps/details?id=com.bitzooma.blackborder&hl=en"
						text-decoration-line="initial"
					>
						Download
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What is the main objective in Black Border?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							To manage border security by inspecting documents, preventing illegal entries, and making crucial decisions.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Is this a realistic simulation?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, it includes realistic elements like document verification, security policies, and the challenges faced by officers.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What skills are needed?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Attention to detail, quick decision-making, and stress management are crucial for success.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Does it have a progression system?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, players advance through ranks, facing more challenging cases and higher stakes as they progress.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer2>
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text" />
			<Override slot="text3" />
		</Components.Footer2>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});